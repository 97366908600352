import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage.vue'),
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: () => import('../views/Introduction.vue'),
  },
  {
    path: '/associationTeam',
    name: 'AssociationTeam',
    component: () => import('../views/AssociationTeam.vue'),
  },
  {
    path: '/associationRules',
    name: 'AssociationRules',
    component: () => import('../views/AssociationRules.vue'),
  },
  {
    path: '/applyMembership',
    name: 'ApplyMembership',
    component: () => import('../views/ApplyMembership.vue'),
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/associationNotice',
    name: 'AssociationNotice',
    component: () => import('../views/AssociationNotice.vue'),
  },
  {
    path: '/associationNews',
    name: 'AssociationNews',
    component: () => import('../views/AssociationNews.vue'),
  },
  {
    path: '/governmentNews',
    name: 'GovernmentNews',
    component: () => import('../views/GovernmentNews.vue'),
  },
  {
    path: '/industryNews',
    name: 'IndustryNews',
    component: () => import('../views/IndustryNews.vue'),
  },
  {
    path: '/legalPolicy',
    name: 'LegalPolicy',
    component: () => import('../views/LegalPolicy.vue'),
  },
  {
    path: '/memberNews',
    name: 'MemberNews',
    component: () => import('../views/MemberNews.vue'),
  },
  {
    path: '/presidentUnit',
    name: 'PresidentUnit',
    component: () => import('../views/PresidentUnit.vue'),
  },
  {
    path: '/councilMembers',
    name: 'CouncilMembers',
    component: () => import('../views/CouncilMembers.vue'),
  },
  {
    path: '/unitMember',
    name: 'UnitMember',
    component: () => import('../views/UnitMember.vue'),
  },
  {
    path: '/popularScience',
    name: 'PopularScience',
    component: () => import('../views/PopularScience.vue'),
  },
  {
    path: '/tellWine',
    name: 'TellWine',
    component: () => import('../views/TellWine.vue'),
  },
  {
    path: '/characterWine',
    name: 'CharacterWine',
    component: () => import('../views/CharacterWine.vue'),
  },
  {
    path: '/associationAwards',
    name: 'AssociationAwards',
    component: () => import('../views/AssociationAwards.vue'),
  },
  {
    path: '/conferenceActivities',
    name: 'ConferenceActivities',
    component: () => import('../views/ConferenceActivities.vue'),
  },
  {
    path: '/exhibitionInformation',
    name: 'ExhibitionInformation',
    component: () => import('../views/ExhibitionInformation.vue'),
  },
  {
    path: '/expertTeam',
    name: 'ExpertTeam',
    component: () => import('../views/ExpertTeam.vue'),
  },
  {
    path: '/contentPage',
    name: 'ContentPage',
    component: () => import('../views/ContentPage.vue'),
  },
];
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
