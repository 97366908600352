/* 封装axios用于发送请求 */
import axios from 'axios';

/*
(1)request 相当于 Axios 的实例对象
(2)为什么要有request,而不是直接用axios
  * 项目开发中，有可能会有两个基地址
  * 不同的接口配置不同（有的要token,有的不要token）
*/
const request = axios.create({
  baseURL: 'http://admin.zysjyxh.com/', // 设置基地址，博主这个请求跨域了因此我设置了代理服务器，因此基地址才这么短
  timeout: 5000, // 请求超时：当5s没有响应就会结束请求
});

// 添加请求拦截器，一下内容是axios的拦截器，可以不用写
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default request;
